import React, {useEffect, useRef, useState} from 'react'
import {
  Button,
  Table,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import logo from "./assets/Vibe-Stream-Logo.png";
import nxtIcn from "./assets/next-button.png";
import preIcn from "./assets/previous-button.png"
import playIcn from "./assets/play-button.png";
import vwAllIcn from "./assets/view-all-button.png";
import stoIcns from "./assets/app-store-logos.jpg";
import lkIcn from "./assets/link-icon.png";
import lkIcnBlk from "./assets/link-icon-blk.png";
import homBtn from "./assets/button-home-filled-outline.png";
import albIcn from "./assets/album-art-icon.png"
import videojs from "video.js";
import "videojs-playlist";
import "videojs-contrib-ads";
import "videojs-ima";
import "video.js/dist/video-js.css";
import "videojs-ima/dist/videojs.ima.css";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import { getPlaylist } from "./service/featured";
import useVideoAnalytics from './hooks/useVideoAnalytics';
import { copyToClipboard } from './helpers/globals';

const Playlist = () => {
  const videoRef = useRef(null);
  const [videos, setVideos] = useState([]);
  const [curTitle, setCurTitle] = useState("");
  const [curArtist, setcurArtist] = useState("");
  const [playlistName, setPlaylist] = useState("");
  const [playlistImg, setPlaylistImg] = useState("");
  const [isPlaylistFnd, setIsPlaylistFnd] = useState(true);
  const [modal, setModal] = useState(false);
  const [copiedTitle, setCopiedTitle] = useState("");
  const [videoUUID, setVideoUUID] = useState("");
  const [heightPlr, setHeightPle] = useState("");
  const [heightDiv, setHeightDiv] = useState("");

  const analytics = useVideoAnalytics();

  const toggle = () => setModal(!modal);

  const changeTitle = (title) => {
    setModal(!modal);
    setCopiedTitle(title);
  };

  const player = useRef(null);
  
  const toHHMMSS = (duration) => {
    let mm = parseInt(duration / 60);
    duration = duration % 60;
    let ss = parseInt(duration);
    if (mm < 10) {
      mm = `0${mm}`;
    }
    if (ss < 10) {
      ss = `0${ss}`;
    }
    return `${mm}:${ss}`;
  };

  const changeVideo = (id, e) => {
    const records = videos;
    records.sort((a, b) =>
      a.order > b.order ? 1 : b.order > a.order ? -1 : 0
    );
    const index = records.findIndex((video) => {
      return video.id === id;
    });
    analytics.record();
    player.current.play()
    player.current.playlist.currentItem(index);
    
  }

  const showThumbnail = (id) => {
    const img = document.getElementById(`hoverimg${id}`);
    img.style.marginTop = 25 + "px";
    img.style.visibility = "visible";
  };

  const hideThumbnail = (id) => {
    const img = document.getElementById(`hoverimg${id}`);
    img.style.visibility = "hidden";
  };

  const showAlbum = (id) => {
    const img = document.getElementById(`hoveralb${id}`);
    if (window.screen.width > 800) {
      img.style.left = window.screen.width / 3 + "px";
    }
    img.style.marginTop = 25 + "px";
    img.style.visibility = "visible";
  };

  const hideAlbum = (id) => {
    const img = document.getElementById(`hoveralb${id}`);
    img.style.visibility = "hidden";
  };

  let IsNoPlaylist = ({ children }) => {
    return !isPlaylistFnd ? children : <></>;
  };

  useEffect(() => {
    const googletag = window.googletag;
    googletag.cmd.push(function () {
      googletag.display("div-gpt-ad-1699300221224-0");
    });
    const imaOptions = {
      adTagUrl:
        "https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22978710279/PlaylistPage&description_url=[placeholder]&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vast&env=vp&unviewed_position_start=1&impl=s&correlator=",
      // disableCustomPlaybackForIOS10Plus: true,
    };
    const arr = window.location.href.split("/");
    let id = null;
    if (arr[arr.length - 1].includes("?")) {
      id = arr[arr.length - 1].split("?")[0];
    }
    else {
      id = arr[arr.length - 1];
    }

    if (id) {
      getPlaylist(id)
        .then((res) => {
          setVideos(res.data.data.videos);
          setPlaylist(res.data.data.name);
          setPlaylistImg(res.data.data.image_url);
          let records = res.data.data.videos;
          records.sort((a, b) =>
            a.order > b.order ? 1 : b.order > a.order ? -1 : 0
          );
          setCurTitle(records[0].title);
          setcurArtist(records[0].artist)
          const urls = [];
          records.forEach((video) => {
            urls.push({
              sources: [
                { src: video.video_url, type: "application/x-mpegURL" },
              ],
              poster: video.thumbnail_url
            });
          });

          const videoOp = {
            controls: true,
            controlBar: {
              pictureInPictureToggle: false,
            },
          };
          player.current = videojs(videoRef.current, videoOp);
          const Button = videojs.getComponent("Button");
          const preBtn = new Button(player.current, {});
          preBtn.controlText("Previous");
          preBtn.addClass("vjs-visible-text");

          const preBtnDom = preBtn.el();
          preBtnDom.innerHTML = `<img src=${preIcn} width="25%" />`;
          preBtnDom.onclick = function (e) {
            player.current.playlist.currentItem(
              player.current.playlist.previousIndex()
            );
          };
          player.current.getChild("ControlBar").addChild(preBtn, {}, 1);

          const nextBtn = new Button(player.current, {});
          nextBtn.controlText("Next");
          nextBtn.addClass("vjs-visible-text");

          const nextBtnDom = nextBtn.el();
          nextBtnDom.innerHTML = `<img src=${nxtIcn} width="25%" />`;
          nextBtnDom.onclick = function (e) {
            player.current.playlist.currentItem(
              player.current.playlist.nextIndex()
            );
          };
          player.current.getChild("ControlBar").addChild(nextBtn, {}, 2);
          player.current.playlist(urls);
          player.current.playlist.autoadvance(0);
          player.current.on("play", () => {
            let curInd = player.current.playlist.currentItem();
            analytics.start(records[curInd].id);
            setCurTitle(records[curInd].title);
            setcurArtist(records[curInd].artist);
            setVideoUUID(records[curInd].video_id);
            for (let i = 0; i < records.length; i++) {
              records[i].is_playing = false;
            }
            records[curInd].is_playing = true;
            setVideos([...records]);
          });
          player.current.ima(imaOptions);
          // player.current.ima.initializeAdDisplayContainer();
          // player.current.ima.requestAds();

          // let firInd = videos[0].video_url.lastIndexOf("/");
          // let ltInd = videos[0].video_url.lastIndexOf(".")
          player.current.on("touchstart", (e) => {
            if (e.target.classList.contains("vjs-playing")) {
              // this.bigPlayButton.show();
              analytics.pause();
              player.current.pause();
            } else {
              player.current.play();
            }
          });
          return () => {
            player.current.dispose();
          };
        })
        .catch((e) => {
          console.log(e);
          setIsPlaylistFnd(false)
        });
    } else {
      setIsPlaylistFnd(false);
    }

    if (window.screen.width > 800) {
      setHeightPle(document.getElementById("featured-player").offsetHeight + "px");
      setHeightDiv(`${window.screen.height}px`);
    } else {
      setHeightPle("auto")
      setHeightDiv("auto")
    }
    
  }, []);

  return (
    <div style={{ backgroundColor: "black" }}>
      <div style={{ width: "100%" }}>
        <div className="img-con-header">
          <div className='link-con'>
            <div style={{
              textAlign: "left",
              color: "white",
              margin: "auto 0",
            }}>
            </div>
            <div style={{
              textAlign: "center",
              margin: "0 auto",
            }}>
              <a href="/">
                <img
                  className="imgLogo"
                  src={logo}
                  width="41%"
                  alt="logo"
                />
              </a>
            </div>
            <div style={{
              textAlign: "right",
              margin: "auto 0",
            }}>
              <a href="/" style={{ margin: "0 auto" }}>
                <img
                  className="imgLogo"
                  src={homBtn}
                  width="41%"
                  alt="home"
                />
              </a>
            </div>
          </div>
          <IsNoPlaylist>
            <div style={{ color: "white" }}>
              <h2>
                Dang it, something went wrong. Please go to{" "}
                <a href="https://gogopix.tv">GOGOPIX.TV</a>
              </h2>
            </div>
          </IsNoPlaylist>
        </div>
        <div style={{
          width: "80%",
          color: "white",
          margin: "2px auto",
        }}>
          <a href={`${window.location.protocol}//${window.location.host}/video/${videoUUID}`}>
            <h4 className="font-semibold">{curTitle}</h4>
          </a>
          <h6>{curArtist}</h6>
        </div>
        <div className="video-con">
          <div className="player-con">
            <video
              id='featured-player'
              ref={videoRef}
              className="video-js vjs-16-9 vjs-big-play-centered"
              playsInline
            />
          </div>
          <div className='playlist-videos-con' style={{ height: heightPlr, overflowY: "auto", }}>
            {videos.length > 0 ? (
              <div style={{ width: "80%", margin: "0 auto" }}>
                {videos
                  .sort((a, b) =>
                    a.order > b.order ? 1 : b.order > a.order ? -1 : 0
                  )
                  .map((record, idx) => {
                    if (record.is_playing) {
                      return (
                        <>
                          <div className='rowSelected'
                            style={{ width: "100%", padding: "1px" }}>
                            <img src={record.thumbnail_url} alt='' width='100%' />
                            <h5 className="font-semibold"
                            >{`${record.title} (Playing)`}</h5>
                            <h6>{record.artist}</h6>
                          </div>
                          <br />
                        </>
                      )
                    } else {
                      return (
                        <div style={{ color: "#ff71ee" }}>
                          <img src={record.thumbnail_url} alt={record.title} width='100%'
                            style={{ cursor: "pointer" }}
                            onClick={changeVideo.bind(this, record.id)} />
                          <h5 className="font-semibold"
                            style={{ cursor: "pointer" }}
                            onClick={changeVideo.bind(this, record.id)}
                          >{record.title}</h5>
                          <h6>{record.artist}</h6>
                          <br />
                        </div>
                      )
                    }
                  })}
              </div>
            ) : (
              <Spinner color="warning">Loading....</Spinner>
            )}
          </div>
        </div>
      </div>
      
      <div
        style={{
          margin: "25px auto 5px auto",
          textAlign: "center",
        }}
      >
        <img
          style={{
            margin: "20px 20px",
          }}
          src={playlistImg}
          alt={playlistName}
        />
      </div>
      <div className="img-con">
        <a href="/allplaylists">
          <img
            className="imgBtn"
            style={{ marginTop: "45px" }}
            src={vwAllIcn}
            width="20%"
            alt="view all"
          />
        </a>
      </div>
      {/* <div className="img-con">
        <a href="https://app.gogopix.co/gogopixtv">
          <img
            className="imgBtn"
            style={{ marginTop: "45px" }}
            src={stoIcns}
            width="30%"
            alt="get the app logo"
          />
        </a>
      </div> */}
      <footer style={{ textAlign: "center", color: "#fff" }}>
        <a href="https://prod-vibestream.sfo3.digitaloceanspaces.com/VibestreambyGOGOPIXInc-termsandconditions.pdf">
          Terms
        </a>{" "}
        |{"  "}
        <a href="https://prod-vibestream.sfo3.digitaloceanspaces.com/VibestreambyGOGOPIXInc-PrivacyPolicy.pdf">
          Privacy Policy
        </a>{" "}
        |{"  "}
        <a href="/contactus">Contact Us</a>
      </footer>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Video URL copied</ModalHeader>
        <ModalBody>
          The video url titled "{copiedTitle}" has been copied to the clipboard.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Playlist;